import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import Button from '../../../components/ui/button'
import { SectionWrap, JobList, JobItem, JobTitle, JobType, JobDesc, JobRequirement } from './style'

const JobsArea = ({
    ButtonOne,
    ...props
}) => {
    const getJobsData = useStaticQuery(graphql`
        query GET_JOBS_DATA {
            intro: careers(id: {eq: "careers-section-intro"}) {
                title
                desc
                email
                text
            }
            jobs: careers(id: {eq: "our-open-jobs"}) {
                jobs {
                  id
                  jobTitle
                  email
                  type
                  description {
                    descOne
                    descTwo
                    descThree
                    descFour
                    descFive
                  }
                  requirement {
                    reqOne
                    reqTwo
                    reqThree
                    reqFour
                    reqFive
                    reqSix
                  }
                }
              }
        }      
    `);
    const { intro: { title,desc}, jobs: { jobs } } = getJobsData

    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            title={title}
                        />
                        <br></br>
                        <h5>{desc}</h5>
                        <br></br>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <JobList>
                            {jobs.map((job, i) => {
                                const isEven = i % 2 === 0;
                                return (
                                    <JobItem key={job.id} isEven={isEven}>
                                        <Row>
                                            <Col lg={3}>
                                                <h6 style={{paddingTop:20}}>POSITION</h6> 
                                                <JobTitle style={{color: '#376ad9'}}>{job.jobTitle}</JobTitle>
                                                <JobType>{job.type}</JobType>
                                                <Button
                                                    {...ButtonOne}   
                                                    to={`mailto:${job.email}?subject=Apply for position ${job.jobTitle}`}>
                                                    Apply Now
                                                </Button>
                                            </Col>
                                            <Col lg={4}>
                                                <h6 style={{paddingTop:20}}>DESCRIPTION</h6> 
                                                <JobDesc>{job.description.descOne}</JobDesc>
                                                <JobDesc>{job.description.descTwo}</JobDesc>
                                                <JobDesc>{job.description.descThree}</JobDesc>
                                                <JobDesc>{job.description.descFour}</JobDesc>
                                                <JobDesc>{job.description.descFive}</JobDesc>
                                            </Col>
                                            <Col lg={5}>
                                                {/* <JobButton>
                                                    <Button varient="outlined" bordercolor="#ddd">Get Started</Button>
                                                </JobButton> */}
                                                {/* <JobColumn>REQUIREMENTS</JobColumn> */}
                                                <h6 style={{paddingTop:20}}>REQUIREMENTS</h6> 
                                                <JobRequirement>{job.requirement.reqOne}</JobRequirement>
                                                <JobRequirement>{job.requirement.reqTwo}</JobRequirement>
                                                <JobRequirement>{job.requirement.reqThree}</JobRequirement>
                                                <JobRequirement>{job.requirement.reqFour}</JobRequirement>
                                                <JobRequirement>{job.requirement.reqFive}</JobRequirement>
                                                <JobRequirement>{job.requirement.reqSix}</JobRequirement>
                                            </Col>
                                        </Row>
                                    </JobItem>
                                )
                            })}
                        </JobList>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

JobsArea.defaultProps = {
    ButtonOne: {
        skin: 'secondary',
        m: '7px',
        color: '#fff',
            hover: {
                bgColor: 'primary',
                color: '#fff',
            }
    }
}
export default JobsArea
