import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import { MdEmail} from "react-icons/md";
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Heading from '../../../../components/ui/heading'
import Button from '../../../../components/ui/button'
import { SectionWrap } from '../../contact-area/contact-four/contact-area.style';

const CTASection = ({
    sectionStyle,
    heading,
    ButtonOne,
    ButtonTwo,
    ...props
}) => {
    const ctaData = useStaticQuery(graphql `
        query CtaThreeImgQuery {
            skills(id: {eq: "cta-section"}) {
                bg_image
              }
            resume: careers(id: {eq: "careers-section-intro"}) {
                title
                desc
                email
                text
            }
        }

    `);
    const { resume: { email, text} } = ctaData;
    const imageData = ctaData.skills.bg_image;
    return ( 
        // <Section {...sectionStyle} bgImage={imageData}>
        <SectionWrap src ={imageData}>
            <Container>
                <Row className="align-items-center text-lg-left text-center">
                    <Col xl={8} lg={7}>
                        <Heading {...heading}>{text}</Heading> 
                    </Col> 
                    <Col xl ={4} lg={5} className="text-center">
                        <Button   
                            {...ButtonOne} 
                            to={`mailto:${email}`}
                            icon={<MdEmail/>}
                            iconposition="left"
                            icondistance="4px"
                            iconsize="16px">
                             Email Your Resume 
                        </Button> 
                    </Col> 
                </Row> 
            </Container> 
        {/* </Section> */}
        </SectionWrap>
    )
}

CTASection.propTypes = {
    heading: PropTypes.object
}

CTASection.defaultProps = {
    sectionStyle: {
        bgColor: '#1d31a7',
        bgposition: 'top 35% right -68px',
        bgsize: 'auto',
        pt: '80px',
        pb: '80px',
        responsive: {
            medium: {
                pt: '60px',
                pb: '60px'
            },
            small: {
                pt: '40px',
                pb: '40px'
            }
        }
    },
    heading: {
        as: 'h4',
        color: '#fff',
        child: {
            color: 'secondary'
        },
        responsive: {
            medium: {
                mb: '10px'
            }
        }
    },
    ButtonOne: {
        skin: 'light',
        m: '7px',
        color: 'primary',
            hover: {
                bgColor: 'secondary',
                color: '#fff',
            }
    }
}


export default CTASection